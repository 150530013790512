import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@mui/material";
import React, { useState } from "react";
import { scroller } from "react-scroll";
import { useSpring, config, animated } from "react-spring";
import HeroImage from "../../../components/layouts/hero-image/HeroImage";
import SDKHeroImage from "../../../components/layouts/hero-image/SDKHeroImage";
import Section from "../../../components/layouts/section/Section";
import styles from "../HeroImageSection.module.scss";

type Props = {};

const ID_PREFIX = "sdk-hero-image-section";

function SDKHeroImageSection({}: Props) {
  const [isVisible, setIsVisible] = useState(false);

  const anim = useSpring({
    from: {
      opacity: 0,
      x: -200,
    },
    to: {
      opacity: 1,
      x: 0,
    },
    config: config.default,
  });

  const anim2 = useSpring({
    from: {
      opacity: 0,
      x: -200,
    },
    to: {
      opacity: 1,
      x: 0,
    },
    config: config.default,
    delay: 300,
  });

  const anim3 = useSpring({
    from: {
      opacity: 0,
      x: -200,
    },
    to: {
      opacity: 1,
      x: 0,
    },
    config: config.default,
    delay: 600,
  });

  const anim4 = useSpring({
    from: {
      x: 100,
      opacity: 0,
    },
    to: {
      x: 0,
      opacity: 1,
    },
    config: config.default,
    delay: 1200,
  });

  const handleChange = (visible: boolean) => {
    if (visible) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const handleButtonClick = () => {
    scroller.scrollTo("waitlist", {
      duration: 1000,
      delay: 0,
      smooth: true,
      offset: 100,
    });
  };

  return (
    <Section id={`${ID_PREFIX}-main`} pogrId={`${ID_PREFIX}-main`}>
      <SDKHeroImage />
      <div
        id={`${ID_PREFIX}-text-overlay`}
        data-pogr-id={`${ID_PREFIX}-text-overlay`}
        className={styles.textOverlay}
      >
        <div
          id={`${ID_PREFIX}-text-box`}
          data-pogr-id={`${ID_PREFIX}-text-box`}
          className={styles.textBox}
        >
          <animated.div
            id={`${ID_PREFIX}-made-for-gamers-animated`}
            data-pogr-id={`${ID_PREFIX}-made-for-gamers-animated`}
            style={anim}
            className={styles.bubble}
          >
            <h4>Made for Gamers</h4>
          </animated.div>
          <animated.h1
            id={`${ID_PREFIX}-unleash-the-power-animated`}
            data-pogr-id={`${ID_PREFIX}-unleash-the-power-animated`}
            style={anim2}
          >
            UNLEASH THE <br />
            POWER
          </animated.h1>
          <animated.h4
            id={`${ID_PREFIX}-pogr-sdk-animated`}
            data-pogr-id={`${ID_PREFIX}-pogr-sdk-animated`}
            style={anim3}
          >
            The powerful and epic <b>POGR SDK</b> will help your studio take
            your games to the next levels!
          </animated.h4>
          <animated.div
            id={`${ID_PREFIX}-buttons-animated`}
            data-pogr-id={`${ID_PREFIX}-buttons-animated`}
            style={anim4}
          >
            <div
              id={`${ID_PREFIX}-button-row`}
              data-pogr-id={`${ID_PREFIX}-button-row`}
              className={styles.row}
            >
              <Button
                id={`${ID_PREFIX}-get-started-button`}
                data-pogr-id={`${ID_PREFIX}-get-started-button`}
                variant="contained"
                color="primary"
                size="large"
                endIcon={
                  <FontAwesomeIcon icon={faCaretRight} height={8} width={6} />
                }
                onClick={handleButtonClick}
              >
                Get started
              </Button>
              <Button
                id={`${ID_PREFIX}-book-demo-button`}
                data-pogr-id={`${ID_PREFIX}-book-demo-button`}
                variant="outlined"
                color="primary"
                size="large"
                onClick={handleButtonClick}
              >
                Book a demo
              </Button>
            </div>
          </animated.div>
        </div>
      </div>
    </Section>
  );
}

export default SDKHeroImageSection;

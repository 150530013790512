import React, { ChangeEvent, FormEvent, useState } from "react";
import ReactGA from "react-ga";
import { useSearchParams } from "react-router-dom";
import { PostMailistLive } from "../../api/mail";
import WaitlistRadio from "../waitlist-radio/WaitlistRadio";
import styles from "./WaitlistForm.module.scss";

type Props = {
  initialPage?: boolean;
};

const ID_PREFIX = "waitlist-form";

function WaitlistForm({ initialPage = true }: Props) {
  let [searchParams, setSearchParams] = useSearchParams();
  const [formData, setformData] = useState({
    email: "",
    developer: initialPage,
  });
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    ReactGA.event({
      category: "Marketing",
      action: "Waitlist Submission",
    });

    setLoading(true);
    setError("");
    const { email, developer } = formData;
    try {
      const res = await PostMailistLive({
        email,
        developer,
        source: window.location.href,
        referrer_code: searchParams.get("join"),
      });
      setSuccess(res.success);

      // Error Handling
      if (res.errors) {
        setError(res.errors[0].msg);
      }
      if (res.error) {
        setError(res.error.msg);
      }
    } catch (error) {
      console.error("[error adding email]", error);
    }
    setLoading(false);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setformData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleRadioChange = (isDev: boolean) => {
    setformData({
      ...formData,
      developer: isDev,
    });
  };

  return (
    <div
      id={`${ID_PREFIX}-container`}
      data-pogr-id={`${ID_PREFIX}-container`}
      className={styles.container}
    >
      <div
        id={`${ID_PREFIX}-title-section`}
        data-pogr-id={`${ID_PREFIX}-title-section`}
        className={styles.titleSection}
      >
        <h2 id={`${ID_PREFIX}-title`} data-pogr-id={`${ID_PREFIX}-title`}>
          Join our waitlist
        </h2>
        <p
          id={`${ID_PREFIX}-description`}
          data-pogr-id={`${ID_PREFIX}-description`}
        >
          Be among the first users to join POGR for exclusive benefits and
          rewards.
          <br />
          We promise you it's worth it ✌️
        </p>
      </div>
      <form
        id={`${ID_PREFIX}-element`}
        data-pogr-id={`${ID_PREFIX}-element`}
        className={styles.formContainer}
        onSubmit={handleSubmit}
      >
        <WaitlistRadio
          checked={formData.developer}
          onClick={handleRadioChange}
        />
        <div
          id={`${ID_PREFIX}-email-container`}
          data-pogr-id={`${ID_PREFIX}-email-container`}
          className={styles.emailContainer}
        >
          <label
            id={`${ID_PREFIX}-email-input-label`}
            data-pogr-id={`${ID_PREFIX}-email-input-label`}
          >
            Email address
          </label>
          <input
            id={`${ID_PREFIX}-email-input`}
            data-pogr-id={`${ID_PREFIX}-email-input`}
            type="text"
            name="email"
            className={`${error && styles.error}`}
            placeholder="Your email address..."
            value={formData.email}
            onChange={handleChange}
          />
        </div>
        <div
          id={`${ID_PREFIX}-button-container`}
          data-pogr-id={`${ID_PREFIX}-button-container`}
          className={styles.buttonContainer}
        >
          {success ? (
            <div
              id={`${ID_PREFIX}-success-button`}
              data-pogr-id={`${ID_PREFIX}-success-button`}
              className={styles.successButton}
            >
              <p
                id={`${ID_PREFIX}-success-text`}
                data-pogr-id={`${ID_PREFIX}-success-text`}
              >
                <strong>
                  You've been added! We are thrilled to have you onboard!
                </strong>
              </p>
              <p
                id={`${ID_PREFIX}-success-callout-text`}
                data-pogr-id={`${ID_PREFIX}-success-callout-text`}
              >
                Please keep an eye out on your inbox for any upcoming updates!
              </p>
            </div>
          ) : (
            <button
              id={`${ID_PREFIX}-add-to-list-button`}
              data-pogr-id={`${ID_PREFIX}-add-to-list-button`}
              disabled={loading}
              type="submit"
            >
              Add me to the list!
            </button>
          )}
          {error && (
            <div
              id={`${ID_PREFIX}-error-container`}
              data-pogr-id={`${ID_PREFIX}-error-container`}
              className={styles.errorButton}
            >
              <p
                id={`${ID_PREFIX}-error-text`}
                data-pogr-id={`${ID_PREFIX}-error-text`}
              >
                <strong>{error}. Please try again</strong>
              </p>
            </div>
          )}
        </div>
      </form>
    </div>
  );
}

export default WaitlistForm;

import React from 'react';
import './App.css';
import AppNavigator from './navigator/AppNavigator';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import useMuiTheme from './styles/useMuiTheme';

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={useMuiTheme}>
      <AppNavigator />
      </ThemeProvider>
    </div>
  );
}

export default App;

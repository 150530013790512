import React, { useState } from "react";
import IntersectionObserverWrapper from "../../components/intersection-observer-wrapper/IntersectionObserverWrapper";
import GateImage from "../../components/layouts/gate-image/GateImage";
import Section from "../../components/layouts/section/Section";
import styles from "./GateSection.module.scss";

type Props = {};

const ID_PREFIX = "gate-section";

function GateSection({}: Props) {
  const [isVisible, setIsVisible] = useState(false);

  const handleChange = (visible: boolean) => {
    if (visible) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  return (
    <IntersectionObserverWrapper
      id={`${ID_PREFIX}-intersection-observer`}
      pogrId={`${ID_PREFIX}-intersection-observer`}
      onChange={handleChange}
    >
      <Section id={`${ID_PREFIX}-main`} pogrId={`${ID_PREFIX}-main`}>
        <GateImage />
        <div
          id={`${ID_PREFIX}-text-overlay`}
          data-pogr-id={`${ID_PREFIX}-text-overlay`}
          className={`${styles.textOverlay} ${
            isVisible ? styles.visible : styles.notvisible
          }`}
        >
          <div
            id={`${ID_PREFIX}-text-box`}
            data-pogr-id={`${ID_PREFIX}-text-box`}
            className={`${styles.textBox} ${
              isVisible ? styles.visible : styles.notvisible
            }`}
          >
            <h2 id={`${ID_PREFIX}-title`} data-pogr-id={`${ID_PREFIX}-title`}>
              <strong>Connect</strong> with your community
            </h2>
            <p
              id={`${ID_PREFIX}-description`}
              data-pogr-id={`${ID_PREFIX}-description`}
            >
              Build out the data pipeline for your games. Begin to understand
              your customers and business intimately on a statistical level.
            </p>
          </div>
        </div>
      </Section>
    </IntersectionObserverWrapper>
  );
}

export default GateSection;

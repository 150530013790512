import React, { useState } from "react";
import { useSpring, config } from "react-spring";
import IntersectionObserverWrapper from "../../components/intersection-observer-wrapper/IntersectionObserverWrapper";
import Section from "../../components/layouts/section/Section";
import styles from "./WidgetSection.module.scss";

type Props = {};

const ID_PREFIX = "widget-section";

function WidgetSection({}: Props) {
  const [isVisible, setIsVisible] = useState(false);

  const handleChange = (visible: boolean) => {
    if (visible) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const anim = useSpring({
    from: {
      opacity: 0,
      x: -200,
    },
    to: {
      opacity: 1,
      x: 0,
    },
    config: config.default,
  });

  return (
    <IntersectionObserverWrapper
      id={`${ID_PREFIX}-intersection-observer`}
      pogrId={`${ID_PREFIX}-intersection-observer`}
      onChange={handleChange}
    >
      <Section id={`${ID_PREFIX}-main`} pogrId={`${ID_PREFIX}-main`}>
        <div
          id={`${ID_PREFIX}-container`}
          data-pogr-id={`${ID_PREFIX}-container`}
          className={styles.container}
        >
          <div
            id={`${ID_PREFIX}-text-section`}
            data-pogr-id={`${ID_PREFIX}-text-section`}
            className={`${styles.textSection} ${
              isVisible ? styles.visible : styles.notvisible
            }`}
          >
            <h2
              id={`${ID_PREFIX}-build-customize-text`}
              data-pogr-id={`${ID_PREFIX}-build-customize-text`}
            >
              <strong>Build & customize</strong>
              <br />
              your ultimate gamer front page!
            </h2>
            <br />
            <p
              id={`${ID_PREFIX}-build-customize-description`}
              data-pogr-id={`${ID_PREFIX}-build-customize-description`}
            >
              A fully loaded platform built with gamers in mind. Showcase your
              gaming accomplishments and the history you take pride in!
              Emphasize the good stuff, leave out the bad stuff and compare
              yourself against the gaming community in the world’s most popular
              video games.
            </p>
          </div>
          <div
            id={`${ID_PREFIX}-widget-container`}
            data-pogr-id={`${ID_PREFIX}-widget-container`}
            className={styles.widgetSection}
          >
            <img
              id={`${ID_PREFIX}-widget-image`}
              data-pogr-id={`${ID_PREFIX}-widget-image`}
              width={"100%"}
              src={"/static/widget-screenshot.webp"}
              alt=""
            />
            {/* <Widget />
            <Widget />
            <Widget />
            <Widget />
            <Widget />
            <Widget /> */}
          </div>
        </div>
        <div
          id={`${ID_PREFIX}-background-image-container`}
          data-pogr-id={`${ID_PREFIX}-background-image-container`}
          className={styles.imageSection}
        >
          <img
            id={`${ID_PREFIX}-background-image`}
            data-pogr-id={`${ID_PREFIX}-background-image`}
            src="/static/mountains-2.webp"
            width="100%"
            alt=""
          />
        </div>
      </Section>
    </IntersectionObserverWrapper>
  );
}

export default WidgetSection;
